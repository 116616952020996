import Rest from '@/services/Rest';

/**
 * @typedef {PasswordRecoveryService}
 */
export default class PasswordRecoveryService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/password-recovery'
}
